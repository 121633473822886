<template>
    <div>
        <div class="smartHospital" v-if="payDetail.status == 1 || payDetail.status == 2">
            <div>树兰（杭州）医院</div>
            <div class="content">
                <div class="pr2">￥</div>
                <div class="price">{{ payDetail.payAmount || 0 }}</div>
            </div>
            <div class="wxpay">
                <div class="wxImg">
                    <img src="https://sho-static.shulan.com/minipro/drugs/payment-weixin-icon.jpg" />
                    <div>微信支付</div>
                </div>
                <van-icon name="checked" color="#12BEAE" size="16" />
            </div>
            <van-button type="primary" style="width:100%;margin-top:100px" @click="payMoney" :disabled="disabled">立即支付</van-button>
        </div>
        <div v-if="payDetail.status != 1 && payDetail.status != 2 && payDetail.status">
            <div class="failsub">
                <img src="https://sho-static.shulan.com/activities/help-questionnaire/icon-wrong.png" class="sbtimg" alt="" />
                <div class="ret-title">{{ getstatusName(payDetail.status) }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast } from 'vant';
import Vue from 'vue';

Vue.use(Toast);
export default {
    data() {
        return {
            appId: '',
            code: '',
            openId: 'obK9FwzI0Bh2-e9DrYPCmydvNDus',
            orderType: '',
            orderNo: '',
            search: '',
            payObj: {},
            disabled: false,
            payDetail: {},
            // 状态，1：待支付，2：待结算，3：已结算，4：已取消，5：退款中，6：已退款， 7：已关闭
            statusName: {
                1: '待支付',
                2: '待结算',
                3: '已结算',
                4: '已取消',
                5: '退款中',
                6: '已退款',
                7: '已关闭',
                8: ' 退款异常',
                9: '重复HIS结算取消'
            }
        };
    },
    created() {
        console.log(window.location.href, 'window.location.href');
        this.appId = this.$route.query.appId || '';
        this.orderNo = this.$route.query.orderNo || '';
        this.orderType = this.$route.query.orderType || '';
        this.code = this.getParameterByName('code');
        //公众号静默授权
        this.getCode();
    },
    methods: {
        getstatusName(status) {
            let data = this.statusName[status] || '';
            return data;
        },
        getParameterByName(name) {
            var url = window.location.search;
            name = name.replace(/[\[\]]/g, '\\$&');
            const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        },
        getCode() {
            //微信静默授权成功
            if (this.code) {
                //已经获取微信openId无需重复获取
                this.getOpenId();
            } else {
                // component_appid=wxda0c44b3005f5f35  服务商模式所需参数
                let currenturl = window.location.href;
                let redirect_uri = encodeURIComponent(currenturl);
                let weixinUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appId}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&component_appid=wxda0c44b3005f5f35#wechat_redirect`;
                console.log(currenturl, redirect_uri, weixinUrl);
                window.location.href = weixinUrl;
            }
        },
        getOpenId() {
            this.$axios.get(`/wechat/open/platform/user/base/info?code=${this.code}&appId=${this.appId}`).then(res => {
                let data = res.data || '';
                this.openId = data.openId || '';
            });
            this.getDetail();
        },
        getDetail() {
            this.$axios.get(`/wisdom-hospital/settlement/order/pay/info/bySettlementNo?settlementNo=${this.orderNo}`).then(res => {
                let data = res.data || '';
                // 状态，1：待支付，2：待结算，3：已结算，4：已取消，5：退款中，6：已退款， 7：已关闭 8 退款异常 9 重复HIS结算取消
                this.payDetail = data;
            });
        },
        payMoney() {
            Toast.loading({
                message: '正在唤起微信支付...',
                forbidClick: true,
                duration: 0
            });
            this.payObj = {};
            let params = {
                payType: 9,
                openId: this.openId,
                settlementNo: this.orderNo
            };
            this.disabled = true;
            this.$axios
                .post('/wisdom-hospital/settlement/order/submit/pay/order', params)
                .then(res => {
                    //微信支付所需参数
                    let data = res.data || {};
                    this.payObj = data;
                    this.JudgeEnv();
                })
                .catch(err => {
                    this.disabled = false;
                    Toast.clear();
                });
        },
        //判断是否是微信浏览器环境内
        JudgeEnv() {
            if (typeof WeixinJSBridge == 'undefined') {
                if (document.addEventListener) {
                    document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
                } else if (document.attachEvent) {
                    document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
                    document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
                }
            } else {
                this.onBridgeReady();
            }
        },
        //微信支付
        onBridgeReady() {
            var _this = this;
            console.log(_this.payObj);
            _this.disabled = true;
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest',
                {
                    appId: _this.payObj.appId, //公众号ID，由商户传入
                    timeStamp: _this.payObj.timestamp, //时间戳，自1970年以来的秒数
                    nonceStr: _this.payObj.nonceStr, //随机串
                    package: _this.payObj.package,
                    signType: _this.payObj.signType, //微信签名方式：
                    paySign: _this.payObj.paySign //微信签名
                },
                function(res) {
                    Toast.clear();
                    _this.disabled = false;
                    console.log(res, '微信支付返回结果');
                    if (res.err_msg == 'get_brand_wcpay_request:ok') {
                    } else {
                        _this.$toast('您已取消支付');
                    }
                }
            );
        }
    }
};
</script>
<style lang="scss">
.smartHospital {
    font-size: 32px;
    padding: 100px 24px;
    display: flex;
    flex-direction: column;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 30px;
    color: #140b04;
    text-align: center;
    .content {
        display: flex;
        justify-content: center;
        align-items: flex-center;
        font-weight: bold;
    }
    .pr2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 5px;
    }
    .price {
        font-size: 60px;
        color: #040814;
        padding: 30px 0 30px;
    }
    .wxpay {
        padding: 55px 30px;
        background: #ffffff;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .wxImg {
        display: flex;
        align-items: center;
        img {
            width: 40px;
            height: 40px;
            margin-right: 20px;
        }
    }
}
.failsub {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 300px 60px 0;
    .ret-title {
        margin-top: 20px;
    }
}
</style>
