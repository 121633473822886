import axios from 'axios';
import router from '../src/router';
import 'vant/lib/toast/style';
import { Toast, Dialog } from 'vant';
import md5 from 'js-md5';
import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';
Vue.use(Toast);
Vue.use(Dialog);
// create an axios instance
const service = axios.create({
    baseURL: '', // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 60000 // request timeout
});
//默认 测试域名： https://shoapi-lan.shulan.com:30443 正式域名：https://sho-api.shulan.com
const urlprefix = {
    //本地开发 域名需要做反向代理
    local: {
        useslhospital: '/hospital',
        commonurl: '/test',
        h5shulan: '/h5shulan',
        databurying: 'databurying'
    },
    development: {
        // useslhospital: "/hospital",
        // commonurl: "/test",
        // h5shulan: "/h5shulan",
        // databurying: "databurying",
        useslhospital: 'https://shoapi-lan.shulan.com:30443',
        commonurl: 'https://shoapi-lan.shulan.com:30443',
        h5shulan: 'https://phpapi-lan.shulan.com:8443',
        databurying: 'https://sho-s.shulan.com:30443'
    },
    production: {
        useslhospital: 'https://shulan-hospital.shulan.com',
        commonurl: 'https://sho-api.shulan.com',
        h5shulan: 'https://h5.shulan.com/v2/shulanwebapi',
        databurying: 'https://s.shulan.com'
    }
};
let backinterface = '';
// request interceptor
service.interceptors.request.use(
    config => {
        console.log(config, '接口参数');
        backinterface = config.url;
        if (config.basurlType) {
            config.url = urlprefix[process.env.NODE_ENV][config.basurlType] + config.url;
            //数据埋点添加请求头
            if (config.basurlType == 'databurying') {
                let ts = new Date().valueOf();
                let tid = 'RFbB';
                let key = 'YPidlsfCrXNCtStdeRYTTwBw6dOinXfU';
                config.headers.tid = tid;
                config.headers.ts = ts;
                config.headers.sign = md5(encodeURIComponent(ts + tid + key, 'utf-8')).toUpperCase();
                // config.headers["Content-Type"] = "application/x-www-form-urlencoded"
                console.log(config, 'config');
            }
        } else {
            console.log(`process.env.NODE_ENV: ${process.env.NODE_ENV}`);

            config.url = urlprefix[process.env.NODE_ENV].commonurl + config.url;
        }

        if (config.tokenSource) {
            var token = localStorage.getItem(`token_${config.tokenSource}`);
            config.headers['Authorization'] = 'bearer ' + token;
        } else {
            if (localStorage.getItem('token')) {
                //新接口token需要统一加上 "bearer ""
                if (config.NewSlToken) {
                    config.headers['Authorization'] = 'bearer ' + localStorage.getItem('token');
                }
                //接口统一，历史接口token 直接传
                else {
                    config.headers['Authorization'] = localStorage.getItem('token');
                }
            }
        }

        config.headers['reqId'] = uuidv4().replace(/-/g, '');
        return config;
    },
    error => {
        // do something with request error
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(
    response => {
        //数据埋点只上报不对返回结果做任何处理
        if (backinterface == '/c') {
            return;
        }
        const res = response.data;
        return response;
    },
    error => {
        //忽略数据埋点错误
        if (backinterface == '/c') {
            return;
        }
        console.log(error.response, 'error.response');
        switch (error.response.status) {
            case 521:
                //微信小程序、app不同环境操作不同
                switch (_currentEnvironment()) {
                    case 'miniprogram':
                        //微信小程序登录过期
                        if (error.response.data.code == 401) {
                            wx.miniProgram.redirectTo({
                                url: `/pages/common-package/login/login`
                            });
                        } else {
                            Dialog.alert({
                                confirmButtonColor: '#12BEAE',
                                title: '提醒',
                                message: error.response.data.msg
                            }).then(() => {});
                        }
                        break;
                    default:
                        Dialog.alert({
                            confirmButtonColor: '#12BEAE',
                            title: '提醒',
                            message: error.response.data.msg
                        }).then(() => {});
                        break;
                }
                break;
            default:
                Dialog.alert({
                    confirmButtonColor: '#12BEAE',
                    title: '提醒',
                    message: error.response.data.msg
                }).then(() => {});
                break;
        }
        return Promise.reject(error);
    }
);
function _currentEnvironment() {
    let environment = '';
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
        wx.miniProgram.getEnv(res => {
            if (res.miniprogram) {
                environment = 'miniprogram';
            }
        });
    }
    environment = environment;
    return environment;
}
export default service;
