import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'amfe-flexible/index';
import './styles/common.scss';
import './static/reset.css';
import './styles/flexcommon.scss';
// import 'vant/lib/toast/style';
// import 'vant/lib/button/style';
// import 'vant/lib/dialog/style';
import 'vant/lib/index.css';
import request from '../utils/request';
import HTTP from './http';
import util from '../utils/util';
import { v4 as uuidv4 } from 'uuid';

import { Toast, Button, Loading, Dialog, ImagePreview, Checkbox, CheckboxGroup, DatetimePicker, Popup, Area, Field, Form, Icon } from 'vant';
Vue.prototype.$axios = request;
Vue.prototype.$HTTP = HTTP;
Vue.prototype.$util = util;

Vue.config.productionTip = false;
Vue.use(Toast);
Vue.use(Button);
Vue.use(Loading);
Vue.use(Dialog);
Vue.use(ImagePreview);
Vue.use(uuidv4);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(Area);
Vue.use(Form);
Vue.use(Field);
Vue.use(Icon);

// rem适配
// window.onresize = setHtmlFontSize
// function setHtmlFontSize() {
//   const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
//   const htmlDom = document.getElementsByTagName('html')[0]
//   htmlDom.style.fontSize = htmlWidth / 3.75 + 'px'
// }
// setHtmlFontSize()
router.beforeEach((to, form, next) => {
    //本地生成一个设备号:did 缓存本地 只需要存储一次
    if (!localStorage.getItem('did')) {
        let did = uuidv4().replace(/-/g, '');
        localStorage.setItem('did', did);
    }
    window.document.title = to.meta.title == undefined ? '' : to.meta.title;
    const Token = !to.query.token ? '' : to.query.token;
    if (Token) {
        localStorage.setItem('token', Token);
    } else {
        // localStorage.setItem('token', localStorage.getItem('token') ? localStorage.getItem('token') : '')
        // localStorage.setItem("token", "");
    }
    next();
});
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
