import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('../views/temperatureAllowance/poster.vue'),
        meta: {
            title: '树兰(杭州)医院互联网医院'
        }
    },
    {
        path: '/alading',
        name: 'alading',
        component: () => import('../views/temperatureAllowance/alading.vue'),
        meta: {
            title: '树兰(杭州)医院互联网医院'
        }
    },
    {
        path: '/jinqiu',
        name: 'index',
        component: () => import('../views/temperatureAllowance/jinqiu.vue'),
        meta: {
            title: '树兰医院'
        }
    },
    {
        path: '/breezeInMarch',
        name: 'index',
        component: () => import('../views/temperatureAllowance/breezeInMarch.vue'),
        meta: {
            title: '树兰医院'
        }
    },
    {
        path: '/nuandong',
        name: 'index',
        component: () => import('../views/temperatureAllowance/nuandong.vue'),
        meta: {
            title: '树兰医院'
        }
    },
    // 华为肝脏脂肪研究项目合作
    {
        path: '/expertConsultation',
        name: 'liverByConsulting',
        component: () => import('../views/liverByConsulting/index.vue')
    },
    {
        path: '/liverByConsulting/index',
        name: 'liverByConsulting',
        component: () => import('../views/liverByConsulting/index.vue')
    },
    {
        path: '/liverByConsulting/detail',
        name: 'liverBydetail',
        component: () => import('../views/liverByConsulting/detail.vue'),
        meta: {
            title: '健康咨询使用说明'
        }
    },
    // 医生端app海报
    // {
    //   path: "/temperatureAllowance/poster",
    //   name: "temperatureAllowance",
    //   component: () => import("../views/temperatureAllowance/poster.vue"),
    //   meta: {
    //     title: "夏日高温补贴",
    //   },
    // },
    // {
    //     path: '/temperatureAllowance/poster',
    //     name: 'temperatureAllowance',
    //     component: () => import('../views/temperatureAllowance/poster.vue'),
    //     meta: {
    //         title: '树兰(杭州)医院互联网医院'
    //     }
    // },
    //生命科技大会临时测试cdn地址
    {
        path: '/temperatureAllowance/lifeTechnology',
        name: 'lifeTechnology',
        component: () => import('../views/temperatureAllowance/lifeTechnology.vue'),
        meta: {
            title: '树兰医院'
        }
    },
    {
        path: '/temperatureAllowance/visitPrescription',
        name: 'temperatureAllowance',
        component: () => import('../views/temperatureAllowance/visitPrescription.vue'),
        meta: {
            title: '复诊开药服务上线'
        }
    },
    {
        path: '/temperatureAllowance/officialWebsite',
        name: 'officialWebsite',
        component: () => import('../views/temperatureAllowance/officialWebsite.vue'),
        meta: {
            title: '树兰互联网医院'
        }
    },
    //眼视光检测
    {
        path: '/temperatureAllowance/ocularOpticalTesting',
        name: 'temperatureAllowance',
        component: () => import('../views/temperatureAllowance/ocularOpticalTesting.vue'),
        meta: {
            title: '树兰医院'
        }
    },
    {
        path: '/temperatureAllowance/ocularOpticalTestingList',
        name: 'temperatureAllowance',
        component: () => import('../views/temperatureAllowance/ocularOpticalTestingList.vue'),
        meta: {
            title: '查询结果'
        }
    },
    {
        path: '/temperatureAllowance/ocularOpticalTestingDetail',
        name: 'temperatureAllowance',
        component: () => import('../views/temperatureAllowance/ocularOpticalTestingDetail.vue'),
        meta: {
            title: '查询结果'
        }
    },
    //videoTechnicalSupport
    {
        path: '/temperatureAllowance/videoTechnicalSupport',
        name: 'temperatureAllowance',
        component: () => import('../views/temperatureAllowance/videoTechnicalSupport.vue'),
        meta: {
            title: '双佳一体机'
        }
    },
    {
        path: '/temperatureAllowance/fatherDay',
        name: 'fatherDay',
        component: () => import('../views/temperatureAllowance/fatherDay.vue'),
        meta: {
            title: '感恩父亲节，患者故事征集活动'
        }
    },
    {
        path: '/temperatureAllowance/wenzhen',
        name: 'temperatureAllowance',
        component: () => import('../views/temperatureAllowance/wenzhen.vue'),
        meta: {
            title: '爱在金秋'
        }
    },
    // 技术支持功能
    {
        path: '/technicalSupport/index',
        name: 'technicalSupport',
        component: () => import('../views/technicalSupport/index.vue'),
        meta: {
            title: '树兰医疗管理股份有限公司'
        }
    },
    //患者端-图文咨询提交订单 支付
    {
        path: '/H5Pay/:appId/:payOrderId/:doctorName/:title/:payFee',
        name: 'H5Pay',
        component: () => import('../views/payment/index.vue'),
        meta: {
            title: '支付'
        }
    },
    //患者端-图文咨询支付成功、失败返回页面
    {
        path: '/paySuccess/wx',
        name: 'paySuccessWx',
        component: () => import('../views/payment/paySuccess.vue')
    },
    // 文章列表  小程序、患者端app
    {
        path: '/articlelist/index',
        name: 'articlelist',
        component: () => import('../views/articlelist/index.vue'),
        meta: {
            title: ''
        }
    },
    // 预约挂号需知 -杭州
    {
        path: '/appointment/hangzhou',
        name: 'articlelist',
        component: () => import('../views/appointment/hangzhou.vue'),
        meta: {
            title: '预约须知'
        }
    },
    // 预约挂号需知 -安吉
    {
        path: '/appointment/anji',
        name: 'articlelist',
        component: () => import('../views/appointment/anji.vue'),
        meta: {
            title: '预约须知'
        }
    },
    //复诊配药用户服务协议
    {
        path: '/appointment/dosageagreement',
        name: 'dosageagreement',
        component: () => import('../views/appointment/dosageagreement.vue'),
        meta: {
            title: '用户服务协议'
        }
    },
    //知情同意书
    {
        path: '/appointment/informedconsent',
        name: 'informedconsent',
        component: () => import('../views/appointment/informedconsent.vue'),
        meta: {
            title: '知情同意书'
        }
    },
    {
        path: '/downloadApp/doctorApp',
        name: 'doctorApp',
        component: () => import('../views/downloadApp/doctorApp.vue'),
        meta: {
            title: '树兰医生'
        }
    },
    {
        path: '/downloadApp/userApp',
        name: 'doctorApp',
        component: () => import('../views/downloadApp/userApp.vue'),
        meta: {
            title: '树兰患者'
        }
    },
    //价格公示
    {
        path: '/appointment/publicPrice',
        name: 'publicPrice',
        component: () => import('../views/appointment/publicPrice.vue'),
        meta: {
            title: '价格公示'
        }
    },
    {
        path: '/clinicalVolunteer/projects',
        name: 'clinicalProjects',
        component: () => import('../views/clinicalVolunteer/projects.vue'),
        meta: {
            title: '临床试验招募'
        }
    },
    {
        path: '/clinicalVolunteer/detail',
        name: 'clinicalDetail',
        component: () => import('../views/clinicalVolunteer/detail.vue'),
        meta: {
            title: '招募详情'
        }
    },
    {
        path: '/clinicalVolunteer/join',
        name: 'clinicalJoin',
        component: () => import('../views/clinicalVolunteer/join.vue'),
        meta: {
            title: '报名信息'
        }
    },
    {
        path: '/clinicalVolunteer/qa',
        name: 'clinicalQa',
        component: () => import('../views/clinicalVolunteer/qa.vue'),
        meta: {
            title: '什么是临床试验'
        }
    },
    //医生注销
    {
        path: '/cancelAccount/applayCancel',
        name: 'applayCancel',
        component: () => import('../views/cancelAccount/applayCancel.vue'),
        meta: {
            title: '注销账号'
        }
    },
    {
        path: '/cancelAccount/cancelReason',
        name: 'cancelReason',
        component: () => import('../views/cancelAccount/cancelReason.vue'),
        meta: {
            title: '注销账号'
        }
    },
    {
        path: '/cancelAccount/cancelResult',
        name: 'cancelResult',
        component: () => import('../views/cancelAccount/cancelResult.vue'),
        meta: {
            title: '注销账号'
        }
    },
    {
        path: '/cancelAccount/cancelNotice',
        name: 'cancelNotice',
        component: () => import('../views/cancelAccount/cancelNotice.vue'),
        meta: {
            title: '注销须知'
        }
    },
    //服务包激活
    {
        path: '/servicePackage/activate',
        name: 'activate',
        component: () => import('../views/servicePackage/activate.vue'),
        meta: {
            title: '树兰为你服务'
        }
    },
    //服务包详情
    {
        path: '/servicePackage/detail',
        name: 'activate',
        component: () => import('../views/servicePackage/detail.vue'),
        meta: {
            title: '树兰为你服务'
        }
    },
    //感染学会直播
    {
        path: '/activity-infected/live',
        name: 'infectedLive',
        component: () => import('../views/activity-infected/live.vue'),
        meta: {
            title: '第十四届中国医师协会感染科医师大会'
        }
    },
    //感染学会直播
    {
        path: '/activity-infected/live-test',
        name: 'infectedLive',
        component: () => import('../views/activity-infected/live-test.vue'),
        meta: {
            title: '第十四届中国医师协会感染科医师大会'
        }
    },
    //感染学会列表
    {
        path: '/activity-infected/list',
        name: 'infectedList',
        component: () => import('../views/activity-infected/list.vue'),
        meta: {
            title: '第十四届中国医师协会感染科医师大会'
        }
    },
    // //感染学会列表
    // {
    //   path: "/activity-infected/list-test",
    //   name: "infectedListTest",
    //   component: () => import("../views/activity-infected/list-test.vue"),
    //   meta: {
    //     title: "第十四届中国医师协会感染科医师大会",
    //   },
    // },

    //感染学会列表
    {
        path: '/activity-infected/questionnaire',
        name: 'questionnaire',
        component: () => import('../views/activity-infected/questionnaire.vue'),
        meta: {
            title: '答题'
        }
    },
    //感染学会登录页
    {
        path: '/activity-infected/login',
        name: 'infectedLogin',
        component: () => import('../views/activity-infected/login.vue'),
        meta: {
            title: '学分申领须知'
        }
    },
    //感染学会登录页
    {
        path: '/activity-infected/learning-live',
        name: 'learningLive',
        component: () => import('../views/activity-infected/learning-live.vue'),
        meta: {
            title: '感染继教班'
        }
    },
    //中间页
    {
        path: '/intermediate-page/alipay',
        name: 'INT-alipay',
        component: () => import('../views/intermediate-page/alipay.vue'),
        meta: {
            title: '树兰医院'
        }
    },
    {
        path: '/qnr/2022-app-qnr',
        name: '2022-app-qnr',
        component: () => import('../views/qnr/2022-app-qnr.vue'),
        meta: {
            title: '2022年度问卷调研'
        }
    },

    // 医生端App个人年终报告
    {
        path: '/personalReport/index',
        name: 'personalReport',
        component: () => import('../views/personalReport/index.vue')
    },

    {
        path: '/qnr/gift-card',
        name: '2022-app-qnr',
        component: () => import('../views/qnr/gift-card.vue'),
        meta: {
            title: '味乐舒提货卡'
        }
    },

    {
        path: '/skipMiniPublicPage/index',
        name: 'skipMiniPublicPageIndex',
        component: () => import('../views/skipMiniPublicPage/index.vue')
    },
    //统一跳转小程序公共h5
    {
        path: '/commonJump/index',
        name: 'commonJump',
        component: () => import('../views/commonJump/index.vue')
    },
    {
        path: '/commonJump/payForAnother',
        name: 'commonJump',
        component: () => import('../views/commonJump/payForAnother.vue')
    },
    {
        path: '/commonJump/otherAuth',
        name: 'commonJump',
        component: () => import('../views/commonJump/otherAuth.vue')
    },
    {
        path: '/commonJump/mina',
        name: 'commonJump',
        component: () => import('../views/commonJump/mina.vue')
    },
    {
        path: '/activity/live',
        name: 'activityLive',
        component: () => import('../views/activity/live.vue')
    },
    {
        path: '/activity/live-mina',
        name: 'activityLiveMina',
        component: () => import('../views/activity/live-mina.vue')
    },
    {
        path: '/activity/video-preview',
        name: 'activityVideoPreview',
        component: () => import('../views/activity/video-preview.vue')
    },
    {
        path: '/video-call/room',
        name: 'videoCallRoom',
        component: () => import('../views/video-call/room.vue')
    },
    {
        path: '/smartHospital/pay',
        name: 'smartHospitalPay',
        component: () => import('../views/smartHospital/pay.vue'),
        meta: {
            title: '支付'
        }
    }
];

const router = new VueRouter({
    routes,
    mode: 'hash'
});

export default router;
